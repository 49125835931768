body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-section {
  background-color: #1A0548;
  background: linear-gradient(to bottom, rgba(26, 5, 72, 1) 0%, rgba(26, 5, 72, 1) 80%, rgba(30, 5, 94, 1) 88%, rgba(48, 10, 131, 1) 100%)
}

#countries-listing {
  margin-top: 21px;
}

#countries-listing .flag .ant-avatar {
  border-radius: 5px;
  width: 32px;
  height: 32px;
  margin-top: 8px;
}

.world-region-header {
  margin-left: 20px;
}

.country.ant-list-item {
  border: none;
}

.country {
  margin-left: 20px;
}

.world-region-header h2 {
  margin-left: 20px;
}

#countries-listing .counter {
  margin-right: 20px;
  text-align: right;
}

.stats-card>.ant-card-head {
  border-bottom: none;
  color: white;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  padding: 0px;
  min-height: unset;
}

.stats-card>.ant-card-body {
  padding: 0px;
}

.highlight {
  fill: #ED008E;
}


.logo-holder {
  text-align: left;
  margin-left: 40px;
  margin-top: 38px;
}

.logo-alpha {
  margin-left: 10px
}

.app-download {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background: #250963;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 20px;
  width: 267px
}

.download-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #FFFFFF;
}

.download-link-btn {
  width: 104px;
  height: 40px;
  background: #5626F4;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  order: 1;
  flex-grow: 0;
}

.download-link-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
}

.view-selector-holder::before {
  content: "";
  width: 100%;
  left: 0;
  top: 50%;
  position: absolute;
  z-index: -1;
  height: 0;
  border-top: 1px solid #E9E9F1;
}
/* .view-selector-holder::after {
  content: "";
  width: 100%;
  right: 0;
  top: 50%;
  position: absolute;
  z-index: -1;
  height: 0;
  border-top: 1px solid #E9E9F1;
} */

.sider {
  max-height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.profile-photo {
  text-align: center;
}

.profile-section {
  padding-top: 28px;
}

.profile-photo img {
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
}

.user-name {
  margin-top: 4px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  color: #2A2E43;
}

.view-picker {
  padding-top: 28px;
}

div>div>div.view-selector-holder {
  display: block;
  height: 48px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 12px;
  background-color: #F6F6F6;
  border-color: #F6F6F6;
  padding: 2px 0px;
}

div>div>div.view-selector-holder>label.slctr-btn {
  margin-top: 0px !important;
  height: 44px;
  line-height: 44px;
  width: 50%;
  margin-top: 28px;
  background-color: #F6F6F6;
  border: none;
  border-color: unset;
  border-radius: 12px;
  color: #9496A1;
}

div>div>div.view-selector-holder>label.slctr-btn.ant-radio-button-wrapper-checked {
  background: #FFFFFF !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: none;
  border-color: unset;
  color: #2A2E43;
}

div>div>div.view-selector-holder>label.slctr-btn.ant-radio-button-wrapper-checked::before {
  display: none;
}

div>div>div.view-selector-holder>label.slctr-btn::before {
  display: none;
}

.stat {
  padding-left: 24px;
  padding-right: 24px;
  min-width: 130px;
  flex-shrink: 0;

}

.stat .count {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
}

.stat .desc {
  text-align: center;
  color: #FFFFFF;
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  white-space: nowrap;
}

div.stats div.divider {
  width: 1px;
  height: 32px;
  background: #FFFFFF;
  opacity: 0.15;
  display: flex;
  align-self: center;
  margin: 0px;

}

.listing-side {
  margin-top: 8px;
  position: relative;
  flex: 1;
}

.listing-head {
  font-size: 17px;
  line-height: 22px;
  font-weight: 700;
  color: #2A2E43;
  padding: 13px 20px;
}

.listing-sub {
  padding: 10px 20px 10px 20px;
  font-size: 17px;
  line-height: 22px;
  color: #2A2E43;
}

.hovered {
  background-color: #F6F6F6 !important;
}

@media (min-width:577px) {
  .country-listing-items-container {
    position: absolute !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
}


svg {
  display: inline-block;
  vertical-align: middle;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.container {
  height: 100%;
}

div.flex-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
}

div.flex-container-404 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}

.tooltip-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
}

.tooltip-inner .country-flag {
  display: inline-block;
}

.tooltip-inner .info-content {
  display: inline-block;
  padding-left: 12px;
}

.tooltip-inner .info-content .country-name {
  font-size: 20px;
  line-height: 25px;
}

.tooltip-inner .info-content .country-additional {
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;  
  color: #B2B2B2;
}

.country-info-additional-listing {
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: #B2B2B2;
}

.tooltip-inner::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

div.ant-tooltip-placement-topRight {
  margin-left: 15px !important;
}

.small-tooltip {
  padding: 12px 16px !important;
  border-radius: 12px !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;

}

.ant-radio-button-wrapper-checked {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: #2A2E43 !important;
}

.download-text>img {
  display: none;
}

.download-text .hidden-sub-logo {
  display: none !important;
}

.country-count {
  text-align: right;
}


@media only screen and (max-width:576px) {
  .ant-row.container {
    overflow-y: scroll;
    /* scroll-snap-type: y mandatory; */
    /* scroll-margin: 10px; */
  }

  .download-text .hidden-sub-logo {
    display: inline-block !important;
    margin-right: 12px;
  }

  div.main-header>div {
    justify-content: space-between;
  }

  .profile-section {
    scroll-snap-align: start;
    scroll-snap-stop: always
  }

  .stats {
    padding-top: 20px;
  }

  .stats .stat {
    min-width: 92px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .stat .count {
    font-size: 20px;
    line-height: 25px;
  }

  .stat .desc {
    font-size: 12px;
    line-height: 15px;
  }

  .world {
    padding-top: 60px;
  }

  .app-download {
    padding-left: 20px;
  }

  .download-text>img {
    display: inline-block;
  }

  .download-text>p {
    display: inline-block;
  }

  .view-picker {
    position: sticky;
    top: 72px;
    z-index: 899;
    background: white;
    padding-top: 18px;
    padding-bottom: 8px;
    /* padding-bottom: 10px; */
  }

  .app-download {
    position: sticky !important;
    z-index: 998;
    top: 0px;
    ;
  }

  .app-download.fixed {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
  }

  .logo-holder {
    order: 2
  }

  .app-download {
    order: 1;
    margin-top: 0px;
    border-radius: 0px;
  }

  .logo-holder {
    margin-left: 20px;
    margin-top: 20px;
  }

  .profile-section {
    border-radius: 16px 16px 0px 0px;
    margin-top: -16px;
    background: white;
  }

  .ant-divider-horizontal:last-child {
    margin-bottom: 0px!important;
  }
}

@media only screen and (max-width: 1190px) {
  div.stats.visited>div>div {
    overflow-x: scroll;
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-content: unset;
  }
}

div.stats.visited>div>div::-webkit-scrollbar {
  display: none;
}
