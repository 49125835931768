@font-face {
    font-family: "Circular Std";
    src: url("./../public/fonts/CircularStd-Book.eot");
    src: url("./../public/fonts/CircularStd-Book.eot?#iefix") format("embedded-opentype"),
        url("./../public/fonts/CircularStd-Book.woff2") format("woff2"),
        url("./../public/fonts/CircularStd-Book.woff") format("woff"),
        url("./../public/fonts/CircularStd-Book.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Circular Std";
    src: url("./../public/fonts/CircularStd-Bold.eot");
    src: url("./../public/fonts/CircularStd-Bold.eot?#iefix") format("embedded-opentype"),
        url("./../public/fonts/CircularStd-Bold.woff2") format("woff2"),
        url("./../public/fonts/CircularStd-Bold.woff") format("woff"),
        url("./../public/fonts/CircularStd-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
body {
    font-family: "Circular Std"!important;
}
